import { Injectable } from '@angular/core';
import { AppConfigService } from '../../app-config.service';
import { interval, Observable } from 'rxjs';

export interface WorkingTime {
  isClosingTime: boolean;
  isRemindingTime: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class WorkingTimeService {
  private readonly idVStartTime: number;
  private readonly idVEndTime: number;
  private readonly idVMinutesToRemind: number;
  private readonly specialHours: { [date: string]: { start: number; end: number } };

  constructor(private configService: AppConfigService) {
    this.idVStartTime = configService.config['idVStartHour'];
    this.idVEndTime = configService.config['idVEndHour'];
    this.idVMinutesToRemind = configService.config['idVMinutesToRemind'];
    this.specialHours = {
      '2024-12-07': { start: 8, end: 16 },
      '2024-12-14': { start: 8, end: 16 },
      '2024-12-20': { start: 8, end: 16 },
      '2024-12-23': { start: 8, end: 16 },
      '2024-12-30': { start: 8, end: 16 },
      '2024-12-31': { start: 8, end: 14 },
    };
  }

  private get currentDate(): string {
    const now = new Date();
    return now.toISOString().split('T')[0];
  }

  private get currentHours(): number {
    return new Date().getHours();
  }

  private get currentMinutes(): number {
    return new Date().getMinutes();
  }

  private get specialWorkingHours(): { start: number; end: number } | null {
    return this.specialHours[this.currentDate] || null;
  }

  public get startTime(): string {
    const startHour = this.specialWorkingHours && this.specialWorkingHours.start != null ? this.specialWorkingHours.start : this.idVStartTime;
    return startHour + ':00';
  }

  public get endTime(): string {
    const endHour = this.specialWorkingHours && this.specialWorkingHours.end != null ? this.specialWorkingHours.end : this.idVEndTime;
    return endHour + ':00';
  }

  public get minutesToClose(): number {
    return this.idVMinutesToRemind;
  }

  public get isWorkingTimeObservable(): Observable<WorkingTime> {
    return new Observable(subscriber => {
      interval(1000).subscribe(() => {
        subscriber.next({
          isClosingTime: !this.isWorkingTime,
          isRemindingTime: this.isRemindingTime,
        });
      });
    });
  }

  public get isWorkingTime(): boolean {
    const startHour = this.specialWorkingHours && this.specialWorkingHours.start != null ? this.specialWorkingHours.start : this.idVStartTime;
    const endHour = this.specialWorkingHours && this.specialWorkingHours.end != null ? this.specialWorkingHours.end : this.idVEndTime;
    return this.currentHours >= startHour && this.currentHours < endHour;
  }

  public get isRemindingTime(): boolean {
    const endHour = this.specialWorkingHours && this.specialWorkingHours.end != null ? this.specialWorkingHours.end : this.idVEndTime;
    const isLastWorkingHour = () => this.currentHours === endHour - 1;
    const isInRemindingMinutes = () => this.currentMinutes >= 60 - this.idVMinutesToRemind;

    return isLastWorkingHour() && isInRemindingMinutes();
  }
}
